import "bootstrap/dist/css/bootstrap.min.css";
import { setupMocks } from "mocks/init";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactDOM from "react-dom";
import { App } from "./App";
import { intercept } from "./services/util/HTTPInterceptor";
import { themeColor } from "./Theme";

import { DynamicComponents } from "components/DynamicComponents/DynamicComponents";
import { QueryClient, QueryClientProvider } from "react-query";
import "./index.scss";
import { ServiceConfig } from "config/ServiceConfig";

//TODO <-- temporary fix for spring map view, delete this when task 49019 completes.
import React from "react";
window.React = React;
//-->

// SpringLogger.init(new Integrations.ReactSentryLogger(ServiceConfig.spring_logger || ''));

intercept();
setupMocks();
DynamicComponents.RegisterDefaultComponents();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
      cacheTime: 3600000,
      refetchOnWindowFocus: false,
    },
  },
});

ServiceConfig.initializePortalConfiguration().then(() => {
  themeColor();
  ReactDOM.render(
    // <React.StrictMode>
    <DndProvider backend={HTML5Backend}>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </DndProvider>,
    // </React.StrictMode>
    document.getElementById("root")
  );
});
