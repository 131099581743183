import axios from "axios";
import { Endpoints } from "config/endpoints";

const TIMEOUT_REQUEST = 30000;

interface IFilterService {
  getServiceEndpoint(): string;
  getFilterDataSources(filterCode: string, params?: any): Promise<any>;
}

export const FilterService: IFilterService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_FILTER_ENDPOINT");
  },

  getFilterDataSources: async (filterCode: string, params?: any) => {
    const FilterServiceUrl = FilterService.getServiceEndpoint();
    const result = await axios.post(
      `${FilterServiceUrl}filter/filterDataSources/${filterCode}`,
      params ? params : null,
      {
        headers: { "Cache-Control": "min-fresh=36000" },
        timeout: TIMEOUT_REQUEST,
      }
    );
    return result.data;
  },
};
