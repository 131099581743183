import axios, { CancelTokenSource } from "axios";
import { IRangeCalendar } from "components/CalendarSchedule/Model/IRangeCalendar";
import { Endpoints } from "config/endpoints";
import { Timesheet } from "models/Timesheets/Timesheet";
import {
  TimesheetStatusField,
  TimesheetStatusUpdatePayload,
} from "models/Timesheets/TimesheetStatusUpdate";
import { TimesheetEventCustomerCoordinates } from "models/Timesheets/TimesheetEventCustomerCoordinates";
import { IReason, IReasonResponse } from "models/Timesheets/Reason";
import { IEventAdjustment } from "hooks/Timesheets/useTimesheetConversation";
import {
  IBusinessRulesQuery,
  TimesheetBusinessStatus,
} from "hooks/Timesheets/useTimesheetStatus";
import { ObjectStatus } from "models/Timesheets/ObjectStatus";
import { IWorkShiftLengthResponse } from "models/TeamManagement/IWorkshift";
import { formatDateToISOString } from "helpers/RouteHelper";
import { IUserPosition } from "pages/TeamManagement/hooks/useCalendarRealTimePosition";
import { RouteEvent } from "models/TeamManagement/RouteEvent";
import { IRoleRelationshipApproval } from "models/Timesheets/RoleRelationshipApproval";
import { MeasurementSystem } from "models/MeasurementSystem";
import { IComponentFiltersSelected } from "components/ConfigurableFilter/ConfigurableFilter";
import { IFilterClauses } from "components/ConfigurableFilter/ConfigurableFilterTypes";
import { QueryClient } from "react-query";
import { FilterService } from "./FilterService";

const queryClient = new QueryClient();
interface IRoutingService {
  getServiceEndpoint(): string;
  getTrackings(): Promise<any>;
  updateTracking(): Promise<any>;
  createTracking(): Promise<any>;
  deleteTracking(): Promise<any>;
  getTrackingByUser: (
    userId: number,
    dtStart: string,
    dtEnd: string,
    cancelToken: CancelTokenSource
  ) => Promise<RouteEvent[]>;
  getTimesheets: (
    userIdList: number[],
    range: IRangeCalendar,
    selectedFilters: IComponentFiltersSelected,
    cancelToken: CancelTokenSource
  ) => Promise<Timesheet[]>;
  getTimesheetsById: (timesheetIdList: string[]) => Promise<Timesheet[]>;
  getTimesheetEventCustomerCoordinates: (
    idAccount: number,
    cancelToken: CancelTokenSource
  ) => Promise<TimesheetEventCustomerCoordinates>;
  changeTimesheetStatus: (
    timesheetIdList: string[],
    newStatus: string,
    fieldType: TimesheetStatusField
  ) => Promise<void>;
  getUserReasonsByFieldType: (
    userId: string,
    fieldType: TimesheetStatusField,
    cancelToken: CancelTokenSource
  ) => Promise<IReason[]>;
  createTimesheetReview: (review: IEventAdjustment[]) => Promise<void>;
  getReasonById: (
    reasonId: number,
    cancelToken: CancelTokenSource
  ) => Promise<IReasonResponse>;
  getStatusByBusinessRules: (
    rules: Record<TimesheetBusinessStatus, IBusinessRulesQuery>,
    cancelToken: CancelTokenSource
  ) => Promise<Record<TimesheetBusinessStatus, ObjectStatus[]>>;
  getOpenWorkShiftLengthByUser: (
    userId: number,
    cancelToken: CancelTokenSource
  ) => Promise<IWorkShiftLengthResponse>;
  getLastPositionAllUsers: (
    cancelToken: CancelTokenSource,
    userIdList?: number[],
  ) => Promise<IUserPosition[]>;
  getTargetRoleRelationshipByUser: (
    userId: string,
    cancelToken: CancelTokenSource
  ) => Promise<IRoleRelationshipApproval>;
  getMeasurementSystemConfiguration: () => Promise<MeasurementSystem>;
  getFirstWeekDayConfiguration: () => Promise<any>;
}

export const RoutingService: IRoutingService = {
  getServiceEndpoint: (): string => {
    return Endpoints.getEndpoint("REACT_APP_ROUTING_ENDPOINT");
  },

  getTrackings: async () => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const { data } = await axios.get(`${routingServiceUrl}tracking`);
    return data;
  },

  updateTracking: async (): Promise<any> => {
    //TODO
    return undefined;
  },

  createTracking: async (): Promise<any> => {
    //TODO
    return undefined;
  },

  deleteTracking: async (): Promise<any> => {
    //TODO
    return undefined;
  },

  getTrackingByUser: async (
    idUser: number,
    dtStart: string,
    dtEnd: string,
    cancelToken: CancelTokenSource
  ): Promise<any> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const localOffset = new Date().getTimezoneOffset() * -1;
    const { data } = await axios.get(
      `${routingServiceUrl}routetracking/user/${idUser}/${dtStart}/${dtEnd}/${localOffset}`,
      {
        headers: { "Cache-Control": "min-fresh=0" },
        cancelToken: cancelToken.token,
      }
    );
    return data;
  },

  getTimesheets: async (
    userIdList: number[],
    range: IRangeCalendar,
    selectedFilters: IComponentFiltersSelected,
    cancelToken: CancelTokenSource
  ): Promise<Timesheet[]> => {
    // get Filter Engine values
    let filterClauses: IFilterClauses = {
      additiveWhereExclude: "",
      additiveWhereInclude: "",
      restrictiveWhere: "",
    };

    if (selectedFilters && selectedFilters.values.length) {
      const filterCacheKey = ["filterClauses", selectedFilters.values];
      filterClauses = await queryClient.fetchQuery<IFilterClauses>({
        queryKey: filterCacheKey,
        queryFn: async () => {
          const filterServiceUrl = FilterService.getServiceEndpoint();
          const result = await axios.post<IFilterClauses>(
            `${filterServiceUrl}filter/filterClauses`,
            selectedFilters
          );
          return result.data ?? filterClauses;
        },
        staleTime: 10 * 60 * 1000, // 10 minutes
      });
    }

    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const formattedUserIdList = userIdList.join(",");
    const dtStart = range.init.toISOString();
    const dtEnd = range.end.toISOString();
    const {
      data,
    } = await axios.post(
      `${routingServiceUrl}routetracking/timesheets?dtStart=${dtStart}&dtEnd=${dtEnd}&idUser=${formattedUserIdList}`,
      filterClauses,
      { cancelToken: cancelToken.token }
    );
    return data;
  },

  getTimesheetsById: async (
    timesheetIdList: string[]
  ): Promise<Timesheet[]> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const formattedTimesheetIdList = timesheetIdList.join(",");
    const { data } = await axios.post(
      `${routingServiceUrl}routetracking/timesheets?_id=${formattedTimesheetIdList}`
    );
    return data;
  },

  getTimesheetEventCustomerCoordinates: async (
    idAccount: number,
    cancelToken: CancelTokenSource
  ): Promise<TimesheetEventCustomerCoordinates> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const {
      data,
    } = await axios.get(
      `${routingServiceUrl}routetracking/timesheets/coordinates/${idAccount}`,
      { cancelToken: cancelToken.token }
    );
    return data;
  },

  changeTimesheetStatus: async (
    timesheetIdList: string[],
    idStatus: string,
    fieldType: TimesheetStatusField
  ): Promise<void> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const payload = timesheetIdList.map((id) => {
      const payloadItem: TimesheetStatusUpdatePayload = {
        _id: id,
      };
      if (fieldType === "Distance") {
        payloadItem.workDistanceStatus = idStatus;
      }
      if (fieldType === "Time") {
        payloadItem.workTimeStatus = idStatus;
      }
      return payloadItem;
    });

    await axios.post(
      `${routingServiceUrl}routetracking/timesheets/status`,
      payload
    );
  },

  getUserReasonsByFieldType: async (
    userId: string,
    fieldType: TimesheetStatusField,
    cancelToken: CancelTokenSource
  ): Promise<IReason[]> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const fieldTypeLower = fieldType.toLocaleLowerCase();
    const {
      data,
    } = await axios.get(
      `${routingServiceUrl}routetracking/timesheets/reasons/all/${userId}/${fieldTypeLower}`,
      { cancelToken: cancelToken.token }
    );
    return data.reasons ?? [];
  },

  createTimesheetReview: async (payload: IEventAdjustment[]) => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    await axios.post(
      `${routingServiceUrl}timesheets/adjustment/review`,
      payload
    );
  },

  getReasonById: async (
    idReason: number,
    cancelToken: CancelTokenSource
  ): Promise<IReasonResponse> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const {
      data,
    } = await axios.get(
      `${routingServiceUrl}routetracking/timesheets/reasons/${idReason}`,
      { cancelToken: cancelToken.token }
    );
    return data;
  },

  getStatusByBusinessRules: async (
    rules: Record<TimesheetBusinessStatus, IBusinessRulesQuery>,
    cancelToken: CancelTokenSource
  ): Promise<Record<TimesheetBusinessStatus, ObjectStatus[]>> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const {
      data,
    } = await axios.post(
      `${routingServiceUrl}routetracking/timesheets/status/businessRules`,
      rules,
      { cancelToken: cancelToken.token }
    );
    return data;
  },

  getOpenWorkShiftLengthByUser: async (
    userId: number,
    cancelToken: CancelTokenSource
  ): Promise<IWorkShiftLengthResponse> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const startOfDay = new Date();
    const endOfDay = new Date();
    startOfDay.setHours(0, 0, 0, 0);
    endOfDay.setHours(23, 59, 59, 59);

    const dtStart = formatDateToISOString(startOfDay);
    const dtEnd = formatDateToISOString(endOfDay);

    const {
      data,
    } = await axios.get(
      `${routingServiceUrl}routetracking/timesheets/shift/length/${userId}/${dtStart}/${dtEnd}`,
      { cancelToken: cancelToken.token }
    );
    return data;
  },

  getLastPositionAllUsers: async (
    cancelToken: CancelTokenSource,
    userIdList?: number[]
  ): Promise<IUserPosition[]> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    let params: string = "";

    if (userIdList && userIdList.length) {
      const formattedUserIdList = userIdList.join(",");
      params = `?idUsers=${formattedUserIdList}`;
    }

    const { data } = await axios.get(
      `${routingServiceUrl}routetracking/last/position${params}`,
      {
        cancelToken: cancelToken.token,
      }
    );

    return data as IUserPosition[];
  },

  getTargetRoleRelationshipByUser: async (
    userId: string,
    cancelToken: CancelTokenSource
  ): Promise<IRoleRelationshipApproval> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const {
      data,
    } = await axios.get(
      `${routingServiceUrl}routetracking/timesheets/rolerelationship/${userId}`,
      { cancelToken: cancelToken.token }
    );
    return data ?? { enabled: false };
  },

  getMeasurementSystemConfiguration: async (): Promise<MeasurementSystem> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const { data } = await axios.get(
      `${routingServiceUrl}measurementSystem/config`
    );
    return data;
  },

  getFirstWeekDayConfiguration: async (): Promise<any> => {
    const routingServiceUrl = RoutingService.getServiceEndpoint();
    const { data } = await axios.get(`${routingServiceUrl}firstWeekDay/config`);
    return data;
  },
};
